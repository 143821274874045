<template>
  <div>
    <div class="pageName">
      <p>字段名称</p>
      <el-input v-model="info.name" placeholder="请输入字段名称"></el-input>
    </div>
     <div class="pageName">
      <p>是否必填</p>
      <el-switch v-model="switchValue" @change="changeSwitch"></el-switch>
    </div>
     <div class="pageName">
      <p>开始日期</p>
       <el-date-picker v-model="start_time" type="date" placeholder="当前日期/固定日期" value-format="timestamp">></el-date-picker>
    </div>
     <div class="pageName">
      <p>结束日期</p>
       <el-date-picker v-model="info.end_time" type="date" placeholder="当前日期/固定日期" value-format="timestamp">></el-date-picker>
    </div>
  </div>
</template>

<script>
export default {
    props:['info'],
    data() {
        return {
            switchValue:this.info.is_required=='1',
        }
    },
    created() {
    },
    computed:{
        start_time(){
            return this.info.start_time*1
        },
        end_time(){
            return this.info.end_time*1
        }
    },
    methods: {
        changeSwitch(e){
            this.info.is_required = e?'1':'0'
        }
    },
}
</script>

<style lang="less" scoped>
.pageName {
    display: flex;
    margin-bottom: 16px;

    p {
      width: 120px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
</style>
