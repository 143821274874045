<template>
  <el-container>
    <div class="main">
      <!-- 组件 -->
      <nav>
        <ul>
          <template v-for="item in navarr">
            <li :class="[active==item.type?'activeli':'']" :key="item.type" @click="handleNav(item.type)" v-if="item.type!='basic'">{{item.name}}</li>
          </template>
        </ul>
      </nav>
      <!-- 显示 -->
      <div class="display">
        <div class="phone">
          <div class="content">
              <!-- 基础设置 -->
                <div :class="[active=='basic'?'component_checked':'component_canset']" @click="changeThis('basic')">
                    <img :src="imgH+'multiversion_phoneheader.png'" class="image">
                    <div class="navigationbar">{{form.name}}</div>
                </div>
                <div class="content_main thin_scroll_column" :style="{'background':form.background_type==1?form.background:`url(${form.background})`}">
                    <vuedraggable v-model="form.form_value"
                        v-bind="{
                            animation: 200,
                            group: 'description',
                            disabled: false,
                            ghostClass: 'ghost',
                            scroll: true
                        }"
                        >
                        <transition-group>
                            <div v-for="(item,index) in form.form_value" :key="index" @click="changeThis(item.type,item.info,index)"
                            :class="['part',item.is_check?'component_checked':'component_canset']" style="padding: 2px;">
                                <!-- 关闭 -->
                                <div class="close" v-if="item.is_check" @click="deleteThis">
                                    <i class="iconfont icon-guanbi"></i>
                                </div>
                                <!-- 轮播图 -->
                                 <banner v-if="item.type=='banner'" :info="item.info"></banner>
                                <!-- 单行文本 -->
                                <inputnew v-if="item.type=='input'" :info="item.info"></inputnew>
                                <!-- 多行文本 -->
                                <textareanew v-if="item.type=='textarea'" :info="item.info"></textareanew>
                                <!-- 下拉框 -->
                                <selectnew v-if="item.type=='select'" :info="item.info"></selectnew>
                                <!-- 单选框 -->
                                <radionew v-if="item.type=='radio'" :info="item.info"></radionew>
                                <!-- 多选框 -->
                                <checkboxnew v-if="item.type=='checkbox'" :info="item.info"></checkboxnew>
                                <!-- 图片 -->
                                <imagenew v-if="item.type=='image'" :info="item.info"></imagenew>
                                <!-- 身份证号码 -->
                                <identity v-if="item.type=='identity'" :info="item.info"></identity>
                                <!-- 手机号码 -->
                                <phone v-if="item.type=='phone'" :info="item.info"></phone>
                                <!-- 日期 -->
                                <date v-if="item.type=='date'" :info="item.info"></date>
                                <!-- 时间段 -->
                                <period v-if="item.type=='period'" :info="item.info"></period>
                                <!-- 日期范围 -->
                                <daterange v-if="item.type=='daterange'" :info="item.info"></daterange>
                                 <!-- 省市区 -->
                                <areanew v-if="item.type=='area'" :info="item.info"></areanew>
                                <!-- 添加协议 -->
                                <protocol v-if="item.type=='protocol'" :info="item.info"></protocol>
                            </div>
                        </transition-group>
                    </vuedraggable>
                </div>
          </div>
        </div>
      </div>
      <!-- 右侧设置 -->
      <div style="overflow:hidden;padding-top:12px;">
          <div class="control">
          <div class="control_title">
            <div>{{getName(active)}}</div>
            <div @click="deleteThis" v-if="active!='basic'">
                删除该组件
                <i class="el-icon-delete"></i>
            </div>
          </div>
          <div class="control_main">
              <!-- 基础设置 -->
              <basiccontrol v-if="active=='basic'" :name="form.name" :background_type="form.background_type"
              :background="form.background" :is_default="form.is_default" @update-data="updateBasic"></basiccontrol>
              <!-- 轮播图 -->
              <bannercontrol v-if="active=='banner'" :info="current_info"></bannercontrol>
              <!-- 单行文本 -->
              <inputcontrol v-if="active=='input'" :info="current_info"></inputcontrol>
              <!-- 多行文本 -->
              <textareacontrol v-if="active=='textarea'" :info="current_info"></textareacontrol>
              <!-- 下拉框 -->
              <selectcontrol v-if="active=='select'" :info="current_info"></selectcontrol>
              <!-- 单选框 -->
              <radiocontrol v-if="active=='radio'" :info="current_info"></radiocontrol>
              <!-- 多选框 -->
              <checkboxcontrol v-if="active=='checkbox'" :info="current_info"></checkboxcontrol>
              <!-- 图片 -->
              <imagecontrol v-if="active=='image'" :info="current_info"></imagecontrol>
              <!-- 身份证号码 -->
              <identitycontrol v-if="active=='identity'" :info="current_info"></identitycontrol>
              <!-- 手机号码 -->
              <phonecontrol v-if="active=='phone'" :info="current_info"></phonecontrol>
              <!-- 日期 -->
              <datecontrol v-if="active=='date'" :info="current_info"></datecontrol>
              <!-- 时间段 -->
              <periodcontrol v-if="active=='period'" :info="current_info"></periodcontrol>
               <!--日期范围 -->
              <daterangecontrol v-if="active=='daterange'" :info="current_info"></daterangecontrol>
              <!-- 省市区 -->
              <areacontrol v-if="active=='area'" :info="current_info"></areacontrol>
              <!-- 添加协议 -->
              <protocolcontrol v-if="active=='protocol'" :info="current_info"></protocolcontrol>
          </div>
        </div>
      </div>
    </div>
    <Preservation @preservation="submit">
      <router-link to slot="return" style="margin-right: 10px">
        <el-button @click="$router.back(-1)">返回</el-button>
      </router-link>
    </Preservation>
  </el-container>
</template>

<script>
import Preservation from '@/components/preservation'
import { imgUrlHead } from '@/util/config.js'
import vuedraggable from 'vuedraggable'
import basiccontrol from './components/basic/basiccontrol'
import banner from './components/banner/banner'
import bannercontrol from './components/banner/bannercontrol'
import inputnew from './components/input/input'
import inputcontrol from './components/input/inputcontrol'
import textareanew from './components/textarea/textarea'
import textareacontrol from './components/textarea/textareacontrol'
import selectnew from './components/select/select'
import selectcontrol from './components/select/selectcontrol'
import radionew from './components/radio/radio'
import radiocontrol from './components/radio/radiocontrol'
import checkboxnew from './components/checkbox/checkbox'
import checkboxcontrol from './components/checkbox/checkboxcontrol'
import imagenew from './components/image/image'
import imagecontrol from './components/image/imagecontrol'
import identity from './components/identity/identity'
import identitycontrol from './components/identity/identitycontrol'
import phone from './components/phone/phone'
import phonecontrol from './components/phone/phonecontrol'
import date from './components/date/date'
import datecontrol from './components/date/datecontrol'
import period from './components/period/period'
import periodcontrol from './components/period/periodcontrol'
import daterange from './components/daterange/daterange'
import daterangecontrol from './components/daterange/daterangecontrol'
import areanew from './components/area/area'
import areacontrol from './components/area/areacontrol'
import protocol from './components/protocol/protocol'
import protocolcontrol from './components/protocol/protocolcontrol'

export default {
  components: {
    Preservation,
    vuedraggable,
    basiccontrol,
    banner,
    bannercontrol,
    inputnew,
    inputcontrol,
    textareanew,
    textareacontrol,
    selectnew,
    selectcontrol,
    radionew,
    radiocontrol,
    checkboxnew,
    checkboxcontrol,
    imagenew,
    imagecontrol,
    identity,
    identitycontrol,
    phone,
    phonecontrol,
    date,
    datecontrol,
    period,
    periodcontrol,
    daterange,
    daterangecontrol,
    areanew,
    areacontrol,
    protocol,
    protocolcontrol
  },
  data () {
    return {
        imgH: imgUrlHead,
      navarr: [{
        type: 'basic',
        name: '基础设置'
      }, {
        type: 'banner',
        name: '轮播图'
      }, {
        type: 'input',
        name: '单行文本'
      }, {
        type: 'textarea',
        name: '多行文本'
      }, {
        type: 'select',
        name: '下拉框'
      }, {
        type: 'radio',
        name: '单选框'
      }, {
        type: 'checkbox',
        name: '多选框'
      }, {
        type: 'image',
        name: '图片'
      }, {
        type: 'identity',
        name: '身份证号码'
      }, {
        type: 'phone',
        name: '手机号'
      }, {
        type: 'date',
        name: '日期'
      }, {
        type: 'period',
        name: '时间段'
      }, {
        type: 'daterange',
        name: '日期范围'
      }, {
        type: 'area',
        name: '省市区'
      }, {
        type: 'protocol',
        name: '添加协议'
      }],
      active: 'basic',

      form:{
        name:'自定义表单',//表单标题
        background_type:'1',//背景类型：1颜色，2背景图
        background:'#ffffff',//背景（颜色码/背景图）
        is_default:'1',//是否默认：0否，1是
        form_value:[]
      },

        current_info:{},

        type:'add',
        id:''
    };
  },
  created(){
      let query = this.$route.query
      if(query.id){
          this.type = 'edit'
          this.id = query.id
          let form_value = JSON.parse(query.form_value)
          form_value.forEach(item=>{
              item.is_check = false
          })
          this.form = {
               name:query.name,
                background_type:query.background_type,
                background:query.background,
                is_default:query.is_default,
                form_value:form_value
          }
      }
  },
  methods: {
    handleNav (str) {
        this.active = str
        if(str!='basic'){

            let info= {}
            // 轮播图
            if(str=='banner'){
                info = {
                    background:'#ffffff',
                    has_margin:'0',
                    list:[{
                        url:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png',
                        path:'跳转页面'
                    }]
                }
            }else if(str=='input'){  // 单行文本
                info = {
                    name:'单行文本',
                    is_required:'1',
                    placeholder:'请填写用户姓名',
                    maxlength:50
                }
            }else if(str=='textarea'){  // 多行文本
                info = {
                    name:'多行文本',
                    is_required:'1',
                    placeholder:'请输入备注',
                    maxlength:500
                }
            }else if(str=='select'){  // 多行文本
                info = {
                    name:'下拉框',
                    is_required:'1',
                    options:['选项1','选项2','选项3']
                }
            }else if(str=='radio'){  // 单选框
                info = {
                    name:'单选框',
                    is_required:'1',
                    radios:['选项1','选项2','选项3']
                }
            }else if(str=='checkbox'){  // 单选框
                info = {
                    name:'多选框',
                    is_required:'1',
                    checkboxs:['选项1','选项2','选项3']
                }
            }else if(str=='image'){  // 图片
                info = {
                    name:'图片',
                    is_required:'1',
                    max_num:9
                }
            }else if(str=='identity'){  // 身份证号码
                info = {
                    name:'身份证号码',
                    is_required:'1',
                    placeholder:"请输入身份证号码"
                }
            }else if(str=='phone'){  // 手机号码
                info = {
                    name:'手机号码',
                    is_required:'1',
                    placeholder:"请输入手机号码"
                }
            }else if(str=='date'){  // 日期
                info = {
                    name:'日期',
                    is_required:'1',
                    default_date:""
                }
            }else if(str=='period'){  // 时间段
                info = {
                    name:'时间段',
                    is_required:'1',
                    options :['时间段1','时间段2','时间段3']
                }
            }else if(str=='daterange'){  // 时间段
                info = {
                    name:'日期范围',
                    is_required:'1',
                    start_time:"",
                    end_time:""
                }
            }else if(str=='daterange'){  // 时间段
                info = {
                    name:'日期范围',
                    is_required:'1',
                    start_time:"",
                    end_time:""
                }
            }else if(str=='area'){  // 省市区
                info = {
                    name:'省市区',
                    is_required:'1',
                    placeholder:"请选择省市区"
                }
            }else if(str=='protocol'){  // 添加协议
                info = {
                    name:'我是协议名称',
                    text:'我是协议展示语',
                    content:""
                }
            }
            // 所有组件都不选中，选中新增的那个
            this.form.form_value.forEach(item=>{
                item.is_check = false
            })
            this.form.form_value.push({
                type:str,
                info:info,
                is_check:true
            })
            this.current_info = info
        }
    },
    submit () {
        let url = ''
        if(this.type == 'add'){
            url = this.$api.customform.add
        }else{
            url = this.$api.customform.edit
            this.form.id = this.id
        }
        this.$axios.post(url, this.form).then(res => {
            if (res.code == 0) {
                let msg = this.type == 'add'?'添加成功':'保存成功'
                this.$message.success(msg);
                this.$router.back(-1)

            } else {
                this.$message.error(res.msg);
            }
        });
    },
    getName (type) {
      return this.navarr.find(item => item.type == type).name
    },
    // 基础设置数据更新
    updateBasic(obj){
        this.form[obj.attr] = obj.data
    },
    // 删除组件
    deleteThis(){
        this.$confirm('确认删除此项？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        })
        .then(() => {
            let index = this.form.form_value.findIndex(item => item.is_check == true)
            this.form.form_value.splice(index, 1)
            index -= 1
            if( index>-1){
                this.form.form_value[index].is_check = true
            }
            this.active = index>-1?this.form.form_value[index].type:'basic'
            this.current_info = index>-1?this.form.form_value[index].info:{}
        })
        .catch(() => {});
    },
    changeThis(active,info={},index=null){
        this.active = active
        this.current_info = info
        this.form.form_value.forEach(item=>{
            item.is_check = false
        })
        if(index || index==0){
            this.form.form_value[index].is_check = true
        }

    }
  }
}
</script>

<style lang="less" scoped>
.el-container {
  min-height: 100%;
  .main {
    height: 100%;
    background: #fff;
    display: flex;
    flex: 1;
    padding-bottom: 60px;
  }
}
nav {
  width: 130px;
  border-right: 1px solid #ddd;
  font-size: 14px;
  li {
    color: #333333;
    padding: 19px 30px;
    border-bottom: 1px solid #ddd;
    position: relative;
    cursor: pointer;
    &::before {
      content: '';
      left: 20px;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 4px;
      height: 4px;
      background: #333333;
      position: absolute;
      border-radius: 50%;
    }
  }
  .activeli {
    color: #3370ff;
    &::before {
      background: #3370ff;
    }
  }
}
.display{
    flex:1;
    padding: 12px;
    .phone {
        width: 384px;
        height:770px;
        background-size: 100% 100%;
        margin: 0 auto;
        background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/phonebg.png);
        padding: 54px 15px 0;
        .content {
            width: 350px;
            height: 650px;
            display: flex;
            flex-direction: column;

            .image{
                width: 100%;
                height: auto;
            }

            .navigationbar{
                height: 40px;
                line-height: 40px;
                font-size: 17px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #1a1a1a;
                text-align: center;
                background-color: #fff;
            }

            .content_main{
                flex: 1;
                overflow-y: auto;
                background-size: cover!important;
                background-repeat: no-repeat!important;
                background-position: center!important;
                font-size: 14px;
            }
            .part{
                position: relative;
                .close{
                    position: absolute;
                    right: 5px;
                    top: 2px;
                    color: red;
                    font-size: 20px;
                    z-index: 30;
                }
            }
        }
    }
}

.control {
  width: 540px;
  box-shadow: 0 0 10px 2px #ddd;
  border-radius: 10px;
  margin-right:12px;
  margin-left:16px;
  .control_title {
    height: 50px;
    padding: 0 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    :nth-child(2){
        color: red;
        cursor: pointer;
    }
  }
  .control_main {
    padding: 26px 26px 40px;
    font-size: 14px;
  }
}
</style>
