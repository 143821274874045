<template>
  <div class="input">
      <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}}</div>
        <el-input type="text" :placeholder="info.placeholder" v-model="text" :maxlength="info.maxlength" class="input_input" size="small"></el-input>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
            text:''
        }
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
        }
    }
</style>
