<template>
  <div>
    <div class="pageName">
      <p>字段名称</p>
      <el-input v-model="info.name" placeholder="请输入字段名称"></el-input>
    </div>
     <div class="pageName">
      <p>是否必填</p>
      <el-switch v-model="switchValue" @change="changeSwitch"></el-switch>
    </div>
     <div class="pageName" style="align-items: flex-start;">
      <p style="margin-top:10px;">选项内容</p>
        <div>
            <div v-for="(item,index) in info.options" :key="index" class="pageName_item">
                <el-input v-model="info.options[index]" :placeholder="'选项'+(index*1+1)"></el-input>
                <div @click="deleteItem(index)" v-if="index>0" style="margin-left:10px;">
                  <i :class="['iconfont', index != 0 ? 'icon-guanbi' : '']"></i>
                </div>
            </div>
            <div @click="addItem" class="addOne">+新增一个</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['info'],
    data() {
        return {
            switchValue:this.info.is_required=='1'
        }
    },
    created() {
    },
    methods: {
        changeSwitch(e){
            this.info.is_required = e?'1':'0'
        },
        deleteItem(index){
            this.info.options.splice(index,1)
        },
        addItem(){
            this.info.options.push('选项'+(this.info.options.length*1+1))
        }
    },
}
</script>

<style lang="less" scoped>
.pageName {
    display: flex;
    margin-bottom: 16px;
    overflow-y: auto;
    .pageName_item{
        display: flex;
        align-items: center;
        & + .pageName_item{
            margin-top: 16px;
        }
    }
    .addOne {
      color: #3c76ff;
      cursor: pointer;
      margin-top: 16px;
    }

    p {
      width: 120px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
</style>
