<template>
  <div class="input">
      <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}}</div>
         <el-select v-model="value" placeholder="请选择" class="input_input" size="small">
            <el-option
            v-for="(item,index) in info.options"
            :key="index"
            :label="item"
            :value="index">
            </el-option>
        </el-select>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
            value:''
        }
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
            width: 100%;
        }
    }
</style>
