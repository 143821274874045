<template>
  <div class="input">
        <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}}</div>
         <el-cascader class="input_input"
          v-model="area_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          :placeholder="info.placeholder"
          :options="areaList"
          size="small"
        ></el-cascader>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
            area_id:'',
            areaList:[]
        }
    },
    created(){
        this.getArea()
    },
    methods:{
        getArea() {
            this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
                if (res.code == 0) {
                    this.areaList = res.result.list;
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
            width: 100%;
        }
    }
</style>
