<template>
  <div>
    <div class="pageName">
      <p>表单标题</p>
      <el-input v-model="name" placeholder="上线后此页面标题"></el-input>
    </div>
    <div class="pageName">
      <p>背景颜色</p>
      <el-radio-group v-model="background_type" @change="changeBackground_type">
        <el-radio label="1">单色</el-radio>
        <el-radio label="2">图片</el-radio>
      </el-radio-group>
    </div>
    <div class="pageName" v-if="background_type==1">
      <p>整体背景色</p>
      <el-color-picker v-model="background" @change="changeColorpicker"></el-color-picker>
      <el-button type="primary" @click="returnColor">重置</el-button>
    </div>
    <div class="pageName" v-else>
      <p>整体背景图</p>
      <ReadyUploadSource
        @getSource="getImg"
        @removeThis="removeImageThis"
        :path="background"
        ></ReadyUploadSource>
        <p style="margin-left:20px;">建议图片尺寸大小不超过1M</p>
    </div>
     <div class="pageName">
      <p>是否默认</p>
      <el-switch v-model="switchValue"></el-switch>
    </div>
  </div>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    props:['name','background_type','background','is_default'],
    components:{
        ReadyUploadSource
    },
    data() {
        return {
            switchValue:this.is_default=='1'
        }
    },
    watch:{
        name(val){
            this.$emit('update-data',{
                attr:'name',
                data:val
            })
        },
        background_type(val){
            this.$emit('update-data',{
                attr:'background_type',
                data:val
            })
        },
        background(val){
            this.$emit('update-data',{
                attr:'background',
                data:val
            })
        },
        switchValue(val){
            this.$emit('update-data',{
                attr:'is_default',
                data:val?'1':'0'
            })
        }
    },
    created() {
    },
    methods: {
        returnColor() {
            this.background = '#ffffff'
        },
        getImg(val) {
            this.background = val.path;
        },
        removeImageThis() {
            this.background = '';
        },
        changeBackground_type(e){
            if(e==1){
                this.background = '#ffffff';
            }else{
                this.background = '';
            }
        },
        changeColorpicker(e){
            if(!e){
                this.background = '#ffffff';
            }
        }
    },
}
</script>

<style lang="less" scoped>
.pageName {
    display: flex;
    margin-bottom: 16px;

    p {
      width: 120px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      max-width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
</style>
