<template>
  <div class="input">
      <div class="title">{{info.is_required=='1'?'*':''}} {{info.name}}</div>
        <el-checkbox-group v-model="checkList" class="input_input" size="small">
            <el-checkbox :label="info.checkboxs[index]" v-for="(item,index) in info.checkboxs" :key="index"></el-checkbox>
        </el-checkbox-group>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
            checkList:[]
        }
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 10px 15px 0px;
        position: relative;
        z-index: 20;
        .input_input{
            margin-top: 10px;
            width: 100%;
        }
        .el-checkbox{
            margin-bottom: 10px;
        }
    }
</style>
