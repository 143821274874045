<template>
  <div>
    <div class="pageName">
      <p>协议名称</p>
      <el-input v-model="info.name" placeholder="我是协议名称"></el-input>
    </div>
     <div class="pageName">
      <p>协议展示语</p>
      <el-input v-model="info.text" placeholder="我是协议展示语"></el-input>
    </div>
     <div class="pageName">协议内容</div>
     <Rich :width="495" class="rich" @soninfo="richContant" :richTxt="info.content"></Rich>
  </div>
</template>

<script>
import Rich from '@/components/richText'
export default {
    props:['info'],
    components: {
        Rich
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        richContant(val) {
            this.info.content = val
        }
    },
}
</script>

<style lang="less" scoped>
.pageName {
    display: flex;
    margin-bottom: 16px;

    p {
      width: 120px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      max-width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
</style>
