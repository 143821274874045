<template>
  <div class="input">
        <div class="title">{{info.text}}《<span>{{info.name}}</span>》</div>
  </div>
</template>

<script>
export default {
    props:['info'],
    data(){
        return {
        }
    },
    created(){
    },
    methods:{
    }
}
</script>

<style lang="less" scoped>
    .input{
        padding: 30px 15px;
        position: relative;
        z-index: 20;
        .title span{
            color: #3c76ff;
        }
        .title span:hover{
            text-decoration: underline;
            cursor: pointer;
        }
    }
</style>
